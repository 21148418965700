.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 400;
  min-height: 70vh;
}

.Baby {
  font-size: 200px;
  line-height: 0;
}

